<template>
  <div class="expense">
    <h2>报考流程</h2>
    <p v-html="message"></p>
    <div class="img">
      <img
        src="https://www.gdnandaedu.com/uploads/202105/1621311675982692.jpg"
      />
      <img
        src="https://www.gdnandaedu.com/uploads/202105/1621311684640441.jpg"
      />
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      message: `&emsp;&emsp;2021年，我省成人高校招生录取实行网上报名、网上录取。为确保考生电子档案的准确性，现制定如下成人高考网上报名实施细则。
<br/>&emsp;&emsp;<strong>第一条</strong> 成人高等学校招生统一考试采取互联网报名方式。网上报名时，考生登陆到省教育考试院（网址为：http://www.eeagd.edu.cn/cr）的成人高考报名系统，了解报考须知以及各成人招生学校招生专业（不含专业计划数），在网上自行录入本人的基本信息、绑定手机号，通过省教育考试院官微（gdsksy）采集上传电子相片并交纳报考费。不具备网上报名条件或无法完成网上报名的考生，可自行到报名点报名。
<br/>&emsp;&emsp;<strong>第二条</strong> 考生通过互联网报名自行录入的基本信息内容为：姓名、性别、出生年月、身份证号码、报考类别、考生类别、考试科目组、通讯地址、邮政编码、本人手机号码（绑定）、个人密码、选择考试的县（区）地点、报名点和院校及专业志愿等。每名考生须绑定一个手机号，作为接收考试成绩、录取结果等信息和志愿信息确认的主要凭证，考生务必确保准确绑定本人手机。绑定非本人手机，造成的一切后果由考生本人承担。一个手机号只能用于一个考生绑定。为确保考试、录取期间的通讯畅通，考生须确保绑定的手机号正常使用。
<br/>&emsp;&emsp;专升本考生须录入专科毕业证年份（4位半角阿拉伯数字）、毕业学校、毕业专业、专科毕业证的证书编号。应届专科毕业生报考专升本时，证书编号可输入“待定”，毕业年份可输入“2022”。应届专科毕业生是指在报名时未取得专科毕业证书但在2022年春季入学时能取得专科毕业证书的考生。
<br/>&emsp;&emsp;<strong>第三条</strong> 成人高考考试类别分为文史类、理工类、外语类、体育类和艺术类五大类。
<br/>&emsp;&emsp;<strong>第四条</strong> 成人高考考生报考类别分为专科升本科、高中起点升本科、高中起点专科。
<br/>&emsp;&emsp;<strong>第五条</strong>  成人高校招生计划形式分为脱产、函授和业余三种。
<br/>&emsp;&emsp;<strong>第六条</strong>  考生必须按规定填报相应的录取批次院校志愿。成人高校录取批次为：第一批专科升本科、高中起点升本科班；第二批高中起点专科脱产班；第三批高中起点专科业余、函授班。
<br/>&emsp;&emsp;<strong>第七条</strong>  各录取批次考生可填报的院校志愿个数如下：第一批专升本2个，高中起点本科志愿1个，第二批高中起点专科脱产志愿2个，第三批高中起点专科非脱产志愿3个。
<br/>&emsp;&emsp;<strong>第八条</strong>  考生可在广东省招生办公室公布的《广东省成人高等学校招生专业目录》或在成人高考报名系统网页上查询所要报考的院校、专业，在网页的志愿栏目上输入院校码、专业码。 
<br/>&emsp;&emsp;<strong>第九条</strong>  报考专升本且已取得专科毕业证书的考生须进行在线专科学历验证。已取得专科毕业证书报考专升本的考生必须在报名系统“专科学历验证”模块中，输入专科毕业证书编码、毕业学校、毕业时间、毕业专业，自行进行专科学历验证，由系统反馈验证结果。报考专升本且尚未取得专科毕业证书的应届专科毕业生须进行在线专科学籍查验，预计毕业时间应在2022年3月31日之前。专科应届毕业生须在报名系统“专科学籍查验”模块中，输入本人身份证号码和姓名，自行进行专科学籍查验，由系统反馈验证结果。
<br/>&emsp;&emsp;报考专科升本科且已经取得专科毕业证书的往届考生，在网上报名时须同时在报名系统“专科学历验证”模块进行专科学历验证。报考专科升本科且尚未取得专科毕业证书的应届考生，在网上报名时须同时在报名系统“专科学籍查验”模块进行专科学籍查验。报名系统连接中国高等教育学生信息网（学信网）“学历认证报告查询系统”和“学籍系统”，将根据考生报考信息和考生专科毕业证信息，自动反馈验证结果。验证结果分“通过”和“不通过”两种，其中“不通过”有“不合格”、“未验证”、“应届待验”、“往届待验”四种状态。若专科学历验证结果为“不通过”，考生须自行前往学信网（https://www.chsi.com.cn/xlrz/index.jsp）网上申请专科学历认证，并凭其出具的“中国高等教育学历认证报告”，于10月13日前向地级市招生办公室提交验证结果，学历验证“不通过”且未能按时向报名点出具“中国高等教育学历认证报告”的考生，以及学籍查验“不通过”的考生，不予发放准考证，不安排考试。所有学历验证未通过的考生，都必须在报名规定时间（9月11日-17日）内，到各市招生办公室（考试中心）指定的报名点现场确认报名，逾期不予报考。交纳了报考费（报考费一旦交纳无法返回）的考生可保留报考资格至10月13日。未交纳报考费的考生不予报考，不予编排考场。考生现场确认报名时必须交验专科毕业证书原件。考生凭假证或其他不符合报考条件的证件报名的，何时发现，何时取消考生的报考或录取资格，由此产生的后果由考生本人负责。
<br/>&emsp;&emsp;报考高中起点本科或专科的考生凭高级中等学校毕业证或同等学力证明可以报考，高级中等教育学校在校生（含应届毕业班学生）不得报考。
<br/>&emsp;&emsp;<strong>第十条</strong>  凡符合免试入学或照顾加分（贫困山区县、年龄加分考生除外）的考生需准备好相关的证明材料，于网上报名时上传证明材料扫描版，在报名时间内把证明材料原件交现场报名点工作人员审核，并由工作人员汇总后交市招生办公室（考试中心）审核，由市招生办公室（考试中心）负责在报名系统录入。
<br/>&emsp;&emsp;<strong>第十一条</strong>  考生网上报名时，需准确选择考试县（区）地点，并按系统的提示（如有）到相关的报名点办理报名审核手续。
<br/>&emsp;&emsp;<strong>第十二条</strong>  网上报名时考生需设置6位数字的个人密码，以便修改本人的资料，打印准考证。
<br/>&emsp;&emsp;<strong>第十三条</strong>  考生进入网上报名页面，须认真阅读报名流程、考试要求，以及《考生守则》、《考生注意事项》和《广东省2021年成人高校招生统一考试诚信考试承诺书》，阅读完毕确认，才可进入填报考生基本信息，否则系统拒绝接受考生报名。考生网上报名正确提交后，需记住系统所给出的网上报名号及个人密码。
<br/>&emsp;&emsp;<strong>第十四条</strong>  须现场审核的考生在现场审核时，考生先提供自己的网上报名号给报名点工作人员。工作人员录入该考生的网上报名号并使用身份证阅读器读取考生的资料,核对相关信息后进行现场资格审核并提交，再由考生进行缴费操作，点击确认报名。报名信息一经确认不予修改，未确认的考生不予安排考场。
<br/>&emsp;&emsp;<strong>第十五条</strong>  建立考生电子档案采取自下而上，县（市、区）、地市、省招生办公室分级管理的模式。考生电子档案一经网上确认，任何人不得擅自更改。考试与录取管理以考生网上确认的报名信息为依据。因考生本人录入的信息错误以及因填报志愿不当原因而落选的，其责任由考生本人负责。
<br/>&emsp;&emsp;本实施细则由广东省教育考试院负责解释。`
    }
  }
}
</script>

<style lang="scss" scoped>
.expense {
  padding: 70px 0px;
  line-height: 30px;
  font-size: 16px;
  .img {
    width: 645px;
    margin: 0px auto;
  }
}
</style>
